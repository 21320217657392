
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'documentDetails',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const imgBoxRef = ref()

    onBeforeMount(() => {
      const { title, id } = route.query
      data.navTitle = title
      data.getInfo(Number(id))
    })

    const data: any = reactive({
      selectedItem: 0,
      navTitle: '',
      info: null,
      encryptData: store.state.app.encryptData,
      imagePreview: '',
      imgSrcList: [],

      async getInfo (id: number) {
        const { res } = await indexApi.handbookInfo({ id })
        // console.log('res', res)
        data.info = res
        data.navTitle = res.parent_name

        // 提取所有图片url
        const imgStrs = data.info.content.match(/<img.*?>/g)
        // console.log('imgStrs', imgStrs)
        // 获取每个img url
        const urls = imgStrs.map((url: any) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1]
        })
        // console.log('urls', urls)
        data.imgSrcList = urls
      },

      onBreadcrumb () {
        router.push(`/document/list?dataItem=${data.encryptData}&title=${data.navTitle}`)
      },

      // 点击事件
      imageChgange (e: any) {
        // console.log('e', e)
        // 点击图片预览
        if (e.target.nodeName === 'IMG') {
          // console.log('currentSrc', e.target.currentSrc)
          data.imagePreview = e.target.currentSrc
          imgBoxRef.value.onPreview()
        }
      }
    })

    return {
      ...toRefs(data),
      imgBoxRef
    }
  }
})
